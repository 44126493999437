import Link from "next/link";
import {Container} from "@material-ui/core";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/styles";
import {SessionContext} from "../providers/session";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../theme";
import {TrackingContext} from "../providers/tracking";
import urlNormalizer from "../../tools/urlNormalization";
import {Language} from "@material-ui/icons";
import countryMapping from '../popup/countryMapping.json';
import {useRouter} from "next/router";

import AccountIcon from "../../public/images/icons/user-circle-thin.svg";
import { useTranslation } from "../../tools/i18n";

const useStyles = makeStyles(() => {
	return {
		wrapper: {
			display: 'none',
			justifyContent: 'space-between',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				padding: '0px 8px'
			}
		},
		links: {
			display: 'flex',
			padding: '4px 0',
			minHeight: 27.7
		},
		link: {
			padding: '2px 12px',
			fontSize: 11,
			textTransform: 'uppercase',
			textDecoration: 'none',
			color: '#666',
			'&:not(:last-child)': {
				borderRight: '1px solid #eaeaea'
			},
			'.fullscreen &:last-child': {
				paddingRight: 0
			}
		},
		countrySelector: {
			display: 'flex',
			alignItems: 'center',
			padding: '0px 12px',
			fontSize: 11,
			textTransform: 'uppercase',
			color: '#666',
			cursor: 'pointer',
			borderRight: '1px solid #eaeaea',
			'& svg': {
				marginRight: 4,
				fontSize: 16
			}
		},
		loginLink: {
			display: 'flex',
			alignItems: 'center',
			'& svg': {
				marginTop: -4,
				marginRight: 4,
				marginBottom: -2
			}
		}
	}
}, {name: 'TopLinks'});

export default function TopLinks() {
	const classes = useStyles();
	const router = useRouter();
	const {t} = useTranslation(['general']);
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isFullscreen = useMediaQuery(theme.breakpoints.up('lg'));
	const {actions: trackingActions} = useContext(TrackingContext);
	const {salesChannel} = useContext(SessionContext);

	const {
		translated: {
			customFields
		}
	} = salesChannel;

	function openCountrySelector() {
		document.dispatchEvent(new CustomEvent("country-selector", {detail: {website: getCurrentWebsite()}}));
	}

	function getCurrentWebsite() {
		let websites = Object.keys(countryMapping.websites);
		let currentWebsite = false;
		let currentBasePath = router.basePath.replace(/\/$/, '').replace('www.', '').split('//')[1];

		websites.forEach((websiteKey) => {
			let website = countryMapping.websites[websiteKey];

			if(website.url.indexOf(currentBasePath) >= 0) {
				currentWebsite = websiteKey;
			}
		});

		return currentWebsite;
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	return (
		!isMobile ?
		<Container className={classes.wrapper} disableGutters={true}>
			<div className={[classes.links, isFullscreen ? 'fullscreen' : ''].join(' ')}>
				{customFields?.toplinks_campaign_text && customFields?.toplinks_campaign_link ?
					<Link href={customFields?.toplinks_campaign_link}>
						<a
							className={classes.link}
							style={{color: '#CA0D0D'}}
							onClick={() => trackingActions.click({type: 'top_links', text: customFields?.toplinks_campaign_text, identifier: urlNormalize(customFields?.toplinks_campaign_link)})}
						>{customFields?.toplinks_campaign_text}</a>
					</Link> : ''
				}
				{customFields?.toplinks_outlet_text && customFields?.toplinks_outlet_link ?
					<Link href={customFields?.toplinks_outlet_link}>
						<a
							className={classes.link}
							style={{color: '#CA0D0D'}}
							onClick={() => trackingActions.click({type: 'top_links', text: customFields?.toplinks_outlet_text, identifier: urlNormalize(customFields?.toplinks_outlet_link)})}
						>{customFields?.toplinks_outlet_text}</a>
					</Link> : ''
				}
				{customFields?.toplinks_current_campaign_text && customFields?.toplinks_current_campaign_link ?
					<Link href={customFields?.toplinks_current_campaign_link}>
						<a
							className={classes.link}
							style={{color: customFields?.toplinks_current_campaign_color}}
							onClick={() => trackingActions.click({type: 'top_links', text: customFields?.toplinks_current_campaign_text, identifier: urlNormalize(customFields?.toplinks_current_campaign_link)})}
						>{customFields?.toplinks_current_campaign_text}</a>
					</Link> : ''
				}
			</div>

			<div className={[classes.links, isFullscreen ? 'fullscreen' : ''].join(' ')}>
				{getCurrentWebsite() && false ?
					<span className={classes.countrySelector}
						  onClick={openCountrySelector}><Language/> {getCurrentWebsite()}</span> : ''
				}

				{customFields?.toplinks_info_text && customFields?.toplinks_info_link ?
					<Link href={customFields?.toplinks_info_link}>
						<a
							className={classes.link}
							onClick={() => trackingActions.click({type: 'top_links', text: customFields?.toplinks_info_text, identifier: urlNormalize(customFields?.toplinks_info_link)})}
						>{customFields?.toplinks_info_text}</a>
					</Link> : ''
				}
				{customFields?.toplinks_customerservice_text && customFields?.toplinks_customerservice_link ?
					<Link href={customFields?.toplinks_customerservice_link}>
						<a
							className={classes.link}
							onClick={() => trackingActions.click({type: 'top_links', text: customFields?.toplinks_customerservice_text, identifier: urlNormalize(customFields?.toplinks_customerservice_link)})}
						>{customFields?.toplinks_customerservice_text}</a>
					</Link> : ''
				}
			</div>
		</Container> : ''
	);
}