import React, {useEffect, useState} from 'react';
import {useTranslation} from '../../tools/i18n';
import {makeStyles} from '@material-ui/styles';
import theme from "../theme";
import Link from "next/link";
import { AppBar, Button } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import {useRouter} from "next/router";
import dynamic from "next/dynamic";
import {ChevronLeft} from "@material-ui/icons";
import SearchIcon from "../../public/images/icons/search-thin.svg"

// Elements
import Topline from "./topline";
import Logo from "./logo";
import Search from "./search/main";
import TopLinks from "./topLinks";
import CheckoutUsps from "./checkoutUsps";
import LazyHydrate from "../../tools/lazyHydrate";
import MobileMenu from "../navigation/mobile/main";
import DesktopMenu from "../navigation/desktop/main";
import Favorites from "./favorites";
import Cart from "./cart";

const useStyles = makeStyles(() => {
	return {
		wrapper: {
			zIndex: 999,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			background: theme.palette.background.element,
			color: 'inherit',
			boxShadow: 'none'
		},
		inner: {
			position: 'relative',
			display: 'grid',
			gridTemplateColumns: '33.333% 33.333% 33.333%',
			alignItems: 'center',
			maxWidth: theme.maxWidth,
			width: '100%',
			padding: '15px 15px',
			[theme.breakpoints.up('md')]: {
				'&:not(.compact)': {
					padding: '15px 20px 0'
				}
			},
			[theme.breakpoints.up('lg')]: {
				padding: 15,
				'&:not(.compact)': {
					padding: '15px 0 0',
					gridTemplateRows: '1fr'
				}
			}
		},
		backToStoreLink: {
			color: 'inherit',
			fontSize: 12,
    		letterSpacing: 0.5,
			textTransform: 'uppercase',
			'& .label': {
				display: 'none'
			},
			'& svg': {
				'.xmas &': {
					fill: '#fff'
				},
			},
			[theme.breakpoints.up('md')]: {
				'& .label': {
					display: 'block',
					fontSize: 12,
					textDecoration: 'none',
					'.xmas &': {
						color: '#fff'
					}
				},
				'& svg': {
					display: 'none'
				}
			}
		},
		checkoutTitle: {
			marginLeft: 10,
			textTransform: 'uppercase',
			fontSize: 14,
			letterSpacing: 1
		},
		search: {
			justifySelf: 'flex-start',
			gridColumn: '1/3',
    		gridRow: '1/1',
			padding: 10,
    		width: 40,
			margin: '-3px 0px -6px 30px',
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
	}
}, {name: 'header'});

export default function Header({menu, footer}) {
	const classes = useStyles();
	const router = useRouter();
	const {t} = useTranslation(['general']);
	const isMobile = theme.breakpoints.down('md');
	const [isCompact, setCompact] = useState(false);
	const [show, setShow] = useState(true);
	const [showSearch, setShowSearch] = useState(false);
	const [lastScrollY, setLastScrollY] = useState(0);
  	const [scrollDirection, setScrollDirection] = useState(null);
  	const [initial, setInitial] = useState(false);
	const [searchFocus, setSearchFocus] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setInitial(true);
		}, 1000);

		document.addEventListener("add-to-cart", (e) => {
			setShow(true);
		});
	}, []);

	useEffect(() => {
		if(router.asPath === '/checkout' || router.asPath.indexOf('/checkout/order-payment') >= 0) {
			setCompact(true);
		} else {
			setCompact(false);
		}

		setShowSearch(false);
	}, [router.asPath]);

	useEffect(() => {
		window.addEventListener("scroll", updateScrollDirection); // add event listener

		return () => {
			window.removeEventListener("scroll", updateScrollDirection); // clean up
		};
	}, [lastScrollY, scrollDirection]);

	useEffect(() => {
		if(scrollDirection === 'down') {
			setShow(false);
		} else {
			setShow(true);
		}

		document.dispatchEvent(new CustomEvent("header-scroll"));
	}, [scrollDirection]);

	function updateScrollDirection () {
		const scrollY = window.pageYOffset;
		const direction = scrollY > lastScrollY ? "down" : "up";
		const threshold = isMobile ? 160 : 190;
		let scrollPos = false;

		if(direction !== scrollDirection) {
			if (direction === "down" && scrollY > (lastScrollY + threshold)) {
				setScrollDirection(direction);
				scrollPos = scrollY;
			} else if (direction === "up" && scrollY < (lastScrollY - (threshold * 3))) {
				setScrollDirection(direction);
				scrollPos = scrollY;
			}
		} else {
			scrollPos = scrollY;
		}

		if (scrollY < threshold) {
			setShow(true);
			setScrollDirection("up");
		}

		if(scrollPos) {
			setLastScrollY(scrollPos);
		}
	}

	return (
		<Slide appear={true} direction="down" in={show} timeout={{enter: initial ? 220 : 0, exit: 195}}>
			<AppBar position={!isCompact ? "sticky" : "static"} className={classes.wrapper}>
				{!isCompact ? <><Topline/><TopLinks/></> : '' }

				<div className={[classes.inner, isCompact ? 'compact' : ''].join(' ')}>
					{!isCompact ?
						<Search show={showSearch} emitShow={e => setShowSearch(e)} searchFocus={searchFocus}/> :
						<Link href={'/'}><a className={classes.backToStoreLink}><Button><ChevronLeft/><span className={'label'}>{t('back-to-store')}</span></Button></a></Link>
					}
					<Logo/>
					{!isCompact ? <Favorites/> : ''}
					{!isCompact ? <Cart/> : ''}
					{!isCompact ? <LazyHydrate id="mobile-menu" on="visible"><MobileMenu menu={menu} footer={footer}/></LazyHydrate> : ''}
					{!isCompact ? <SearchIcon className={classes.search} width={20} onClick={() => {setShowSearch(!showSearch);}}/> : ''}
				</div>

				{!isCompact ? <LazyHydrate id="desktop-menu" on="touch"><DesktopMenu menu={menu}/></LazyHydrate> : ''}
				{isCompact ? <CheckoutUsps/> : ''}
			</AppBar>
		</Slide>
	);
}
