import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import formatPrice from "functions/priceFormat";
import theme from "../../theme";
import { SessionContext } from "../../providers/session";
import { useTranslation } from '../../../tools/i18n';
import { Badge, Button, IconButton, Typography, Drawer, CircularProgress } from "@material-ui/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { ErrorContext } from "../../providers/error";
import CartIcon from "../../../public/images/icons/varukorg-thin.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "../../../public/images/icons/close.svg";
import ElevateUpsell from "./elevateUpsell";

import CheckoutCartItem from "../../checkout/item";
import ExpressWrapper from "./expressWrapper";

const useStyles = makeStyles({
    "@keyframes pulse": {
        "0%": {
            opacity: 1,
        },
        "50%": {
            opacity: 0.85,
            backgroundColor: '#041e3a',
        },
        "100%": {
            opacity: 1,
        }
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gridColumn: '3/3',
        gridRow: '1/1',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginRight: -10
        }
    },
    loading: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(255,255,255,0.5)'
	},
    drawer: {
        maxWidth: '90vw',
    },
    drawerClose: {
        position: 'absolute',
        top: 4,
        left: 7,
        zIndex: 10,
        marginLeft: 'auto',
        padding: 8,
        cursor: 'pointer'
    },
    trigger: {
        position: 'relative',
        padding: 10,
        height: 40,
        cursor: 'pointer',
    },
    obscure: {
        content: '""',
        position: 'fixed',
        zIndex: 999,
        top: '0',
        left: 0,
        right: 0,
        height: '110vh',
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,0.2)',
        transition: 'opacity 0.35s ease-in'
    },
    icon: {
        display: 'block',
        width: 20,
        height: 20,
        '.xmas &': {
            color: '#000 !important',
        },
    },
    badge: {
        verticalAlign: 'initial',
    },
    badgeCount: {
        padding: '0 4px',
        fontSize: 11,
        background: "#000",
        color: theme.palette.secondary.text,
        '.xmas &': {
            background: '#fff',
            color: '#000'
        }
    },
    cartClose: {
        position: 'absolute',
        top: 4,
        left: 6,
        padding: 8,
        backgroundColor: 'white',
        zIndex: 1001,
        color: '#000',
        boxShadow: theme.boxShadow[2],
        '&:hover': {
            backgroundColor: 'white'
        },
        [theme.breakpoints.down('xs')]: {
            padding: 4,
            '& svg': {
                width: 20,
                height: 20
            }
        }
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: '100%'
        }
    },
    content: {
        display: 'none',
        flexDirection: 'row-reverse',
        backgroundColor: 'white',
        borderRadius: 3,
        boxShadow: theme.boxShadow[3],
        maxHeight: 'calc(var(--app-height) - 110px)',
        overscrollBehavior: 'none',
        '&.open': {
            display: 'flex',
            position: 'absolute',
            top: '100%',
            right: 1,
            zIndex: 1000,
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            width: 'calc(100vw - 30px)',
            overflowY: 'auto'
        }
    },
    main: {
        display: 'flex',
        position: 'relative',
        flexShrink: 0,
        flexWrap: 'wrap',
        width: '100%',
        maxHeight: '80vh',
        color: '#000',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            width: 400
        }
    },
    title: {
        padding: '20px 15px 0 15px',
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            marginTop: 8,
            marginBottom: 10,
            textAlign: 'center'
        }
    },
    itemsWrapper: {
        width: '100%',
        overflowY: 'auto',
        '&.empty': {
            height: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 200,
            maxHeight: 380
        }
    },
    items: {
        display: 'flex',
        flexDirection: 'column-reverse',
        listStyle: 'none',
        width: '100%',
        margin: 0,
        padding: '0px 20px 0',
        '& .empty-cart': {
            margin: '20px 0 40px',
            fontSize: 12,
            textAlign: 'center',
            color: '#666'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0px 15px 0',
        }
    },
    totals: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        marginTop: 'auto',
        padding: '0 20px 20px',
        width: '100%',
        '&.empty': {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 15px 20px',
        },
    },
    orderSummary: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 0 20px',
        fontSize: 13,
        '& > div': {
            paddingTop: 8,
            paddingBottom: 3,
            '&:not(:first-of-type)': {
                borderTop: '1px solid #f0f0f0'
            }
        }
    },
    freeShipping: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '& .label': {
            fontSize: 13,
            fontStyle: 'italic'
        }
    },
    regular: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 5,
        fontWeight: 400,
        fontSize: 13,
    },
    shipping: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 5,
        fontWeight: 400,
        fontSize: 13,
    },
    discount: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 2,
        color: '#CA0D0D',
        '& .label': {
            fontSize: 13
        }
    },
    total: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: 2,
        fontWeight: 600,
        fontSize: 13,
    },
    goToCheckout: {
        ...theme.button.purchase,
        flexGrow: 0,
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 2,
        backgroundColor: '#01011f',
        '&.open': {
            animation: '$pulse 1s 1',
            animationDelay: 1500,
            animationTimingFunction: 'ease-in-out',
        },
        '&:hover': {
            ...theme.button.purchaseHover,
        },
        '.xmas &': {
            backgroundColor: theme?.palette['xmas'].main + '!important',
            '&.open': {
                animation: 'unset',
            },
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        },
    },
    close: {
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: -5,
        fontSize: 13,
        color: "#666",
        cursor: 'pointer',
        borderBottom: '1px solid #ababab',
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0
        }
    }
}, { name: "Cart" });

export default function Cart() {
    const router = useRouter();
    const { actions: sessionActions, cart, paymentMethod, shippingMethods } = useContext(SessionContext);
    const { actions } = useContext(ErrorContext);
    const [open, setOpen] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const cartRef = useRef(cart);
    const cartElement = useRef(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const allowedErrorMessages = [
        'product-stock-reached'
    ];
    const expressShippingSkus = {
        '9941': '3b41d892fd584e4b9c2259ba40922328',
        '9942': '36663c2aac7c4896acdccc02a9ffddcc'
    };

    useEffect(() => {
        document.addEventListener("add-to-cart", (e) => {
            if (router.asPath === '/checkout') {
                return;
            }

            setTimeout(() => {
                setOpen(true);
            }, 250);
        });

        document.addEventListener("header-scroll", (e) => {
            setOpen(false);
        });
    }, []);

    // Make sure cartRef is up to date
    useEffect(() => {
        cartRef.current = cart;
    }, [cart]);

    useEffect(() => {
        if (open && cartElement.current && typeof cartElement.current.scrollTo !== 'undefined') {
            cartElement.current.scrollTo(0, 0);
        }
    }, [open]);

    useEffect(() => {
        let errors = cart.errors;
        let errorKeys = Object.keys(errors);

        errorKeys.forEach((errorKey) => {
            let error = errors[errorKey];

            if (allowedErrorMessages.indexOf(error.messageKey) < 0) {
                return;
            }

            actions.addError({
                id: error.id,
                type: 'warning',
                messageKey: error.messageKey,
                messageData: {
                    name: error.name,
                    stock: error.quantity
                }
            });
        });
    }, [cart.errors]);

    useEffect(() => {
        setOpen(false);
    }, [router.asPath]);

    function getCartQuantity() {
        let quantity = 0;

        cart.lineItems.forEach((item) => {
            if (item.type === 'product' && Object.keys(expressShippingSkus).indexOf(item.payload?.productNumber) < 0) {
                quantity += item.quantity;
            }
        });

        return quantity;
    }

    function toggleCart() {
		setOpen(!open);
    }

    function getTotalCartPrice() {
        let amount = cart ? cart.price.totalPrice : 0;

        return formatPrice(amount);
    }

    function getShippingPrice() {
		if(!cart.deliveries) {
			return 0;
		}

		let totalShippingCost = 0;
		cart.deliveries.forEach((delivery) => {
			totalShippingCost += delivery.shippingCosts.totalPrice;
		});

		return totalShippingCost;
    }

    function renderCartItem(item, index) {
        if (item.type === 'promotion' || Object.keys(expressShippingSkus).indexOf(item.payload?.productNumber) >= 0) {
            return '';
        }

        return <CheckoutCartItem item={item} cart={cart} actions={sessionActions} suspend={() => {
            setInProgress(true);
        }} resume={() => {
            setInProgress(false);
        }} isLocked={false} reload={() => {}} compact={true} key={index}/>;
    }

    function filterPromotions(item) {
		let remove = false;

		if (item.type !== 'promotion') {
			remove = true;
		} else if (item.type === 'promotion') {
			if(item.label.indexOf('split:') >= 0) {
				remove = true;
			} else if (item.payload.discountScope === 'delivery') {
				remove = true;
			}
		}

		return remove;
	}

    function calculateRegularPrice() {
		let totalValue = 0;

		cart.lineItems.filter((item) => {
			return filterPromotions(item);
		}).forEach((lineItem) => {
			totalValue += lineItem.price.listPrice ? (lineItem.price.listPrice.price * lineItem.price.quantity) : lineItem.price.totalPrice
		});

		return totalValue;
	}

    function showExpressCheckout() {
        return paymentMethod?.translated?.name !== 'Klarna Checkout';
    }

    function renderCartContent() {
        return (
            <div className={classes.contentWrapper}>
                <div className={classes.main}>
                    {inProgress ? <div className={classes.loading}><CircularProgress/></div> : ''}
                    <div className={classes.itemsWrapper} ref={cartElement}>
                        <Typography className={classes.title}>{t('your-cart')}</Typography>
                        <ul className={[classes.items, cart.lineItems.length === 0 ? 'empty' : ''].join(' ')}>
                            {cart.lineItems.map((item, index) => renderCartItem(item, index))}
                            {cart.lineItems.length == 0 ?
                                <Typography variant="body1" className="empty-cart">{t('empty-cart')}</Typography> : ''}
                        </ul>
                    </div>
                    <div className={[classes.totals, cart.lineItems.length === 0 ? 'empty' : ''].join(' ')}>
                        <div className={classes.orderSummary}>
                            <div className={classes.regular} style={{display: 'none'}}>
                                {<span className="label">{t('regular-price')}</span>}
                                <Typography variant="inherit">{formatPrice(calculateRegularPrice())}</Typography>
                            </div>
                            <div className={classes.shipping}>
                                {<span className="label">{t('shipping')}</span>}
                                <Typography variant="inherit">{getShippingPrice() > 0 ? formatPrice(getShippingPrice()) : t('free-shipping')}</Typography>
                            </div>
                            <div className={classes.discount}
                                 style={{ display: sessionActions.getCartDiscount() > 0 ? 'none' : 'none' }}><span
                                className={"label"}>{t('discounts')}</span><span>{formatPrice(-sessionActions.getCartDiscount())}</span>
                            </div>
                            <div className={classes.total}>
                                {<span className="label">{t('total-sum')}</span>}
                                <Typography variant="inherit">{getTotalCartPrice()}</Typography>
                            </div>
                        </div>
                        <Link href="/checkout">
                            <Button
                            className={[classes.goToCheckout, open ? 'open' : ''].join(' ')}>{t('go-to-checkout')}</Button>
                        </Link>

                        {showExpressCheckout() ? <ExpressWrapper inProgress={inProgress} setInProgress={setInProgress} /> : <span className={classes.close} onClick={() => setOpen(false)}>{t('continue-shopping')}</span>}
                    </div>
                </div>
                {!showExpressCheckout() ? <ElevateUpsell/> : ''}
            </div>
        );
    }

    return (
        <>
            {open ? <span className={classes.obscure} onClick={toggleCart}></span> : ''}
            <div className={classes.wrapper}>
                <div className={classes.trigger} onClick={toggleCart}>
                    <Badge badgeContent={getCartQuantity()} classes={{ root: classes.badge, badge: classes.badgeCount }}>
                        <CartIcon className={classes.icon}/>
                    </Badge>
                    {open ?
                        <IconButton className={[classes.cartClose,'cart'].join(' ')} size={"small"} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpen(false);
                        }}>
                            <CloseIcon width={16}/>
                        </IconButton>
                        : ''}
                </div>
                {
                    isMobile ? <Drawer anchor="right"
                                    open={open}
                                    onClose={toggleCart}
                                    ModalProps={{ keepMounted: true }}
                                    className={['drawer', theme?.currentTheme?.name].join(' ')}
                                    classes={{paper: classes.drawer}}
                                    style={{ height: '100vh' }}>
                        <CloseIcon className={classes.drawerClose} onClick={toggleCart} width={32}/>
                        {renderCartContent()}
                    </Drawer> : <div className={[classes.content, open ? 'open' : ''].join(' ')}>{renderCartContent()}</div>
                }
            </div>
        </>
    );
}
